import firebase from "firebase";
import "firebase/firestore";
import "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBXsQOFG5gupUTNb8sHHwwESvyJsCSLw_M",
  authDomain: "deepx-44040.firebaseapp.com",
  projectId: "deepx-44040",
  storageBucket: "deepx-44040.appspot.com",
  messagingSenderId: "854044323331",
  appId: "1:854044323331:web:7d16b496effaa365f62836",
  measurementId: "G-XTRNYRYX7L",
};

const app = firebase.initializeApp(firebaseConfig);
export const db = app.firestore();
export const analytics = app.analytics();

export const auth = firebase.auth();
export const storage = firebase.storage;
export const storageRef = firebase.storage().ref();
export const firestore = firebase.firestore;

const { Timestamp, GeoPoint } = firebase.firestore;
export { Timestamp, GeoPoint };
