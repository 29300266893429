import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import Vuex from "vuex";
import router from "./router";

const fb = require("@/firebaseConfig.js");
import { store } from "@/storeConfig.js";

Vue.use(Vuex);
Vue.prototype.$fb = fb;
Vue.config.productionTip = false;

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
