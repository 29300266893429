import Vue from "vue";
import VueRouter from "vue-router";

import firebase from "firebase";
import { store } from "@/storeConfig.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/HomeView.vue"),
  },
  {
    path: "/paint/:downloadUrl/:idd",
    name: "ImageMasking",
    props: true,
    component: () => import("@/views/ImageMasking.vue"),
  },
  {
    path: "/result/:downloadUrl",
    name: "ImageView",
    props: true,
    component: () => import("@/views/ImageView.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/LoginView.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (
    store &&
    store.state &&
    store.state.userData &&
    store.state.userData.ref
  ) {
    store.state.userData.ref.update({
      lastLoggedAt: new Date(),
    });
  }

  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);
  const user = firebase.auth().currentUser;

  if (requiresAuth) {
    if (store.state.userData && user) {
      next();
    } else {
      next({
        name: "LoginView",
      });
    }
  } else {
    next();
  }
});

export default router;
