import Vue from "vue";
import Vuex from "vuex";
import pathify, { make } from "vuex-pathify";
import VuexPersist from "vuex-persist";
import firebase from "firebase";

Vue.use(Vuex);

const vuexLocalStorage = new VuexPersist({
  key: "vuex",
  storage: window.localStorage, // or window.sessionStorage or localForage
  reducer: (state) => ({
    userdata: state.userData,
  }),
});

const state = {
  userData: null,
};
const mutations = make.mutations(state);

const getters = {
  routerChanged: (state) => state.routerChanged,
};

const actions = {
  signOut() {
    firebase
      .auth()
      .signOut()
      .then(() => {
        store.set("userData", null);
        location.reload();
      });
  },
};
export const store = new Vuex.Store({
  plugins: [vuexLocalStorage.plugin, pathify.plugin],
  state,
  getters,
  mutations,
  actions,
});
